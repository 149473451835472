export default {
    domains: [
        {
            title: 'Welcome',
            messages: ['welcomeMessage'],
        },
        {
            title: 'Organization Assessment',
            messages: ['organizationAssessment'],
        },
        {
            title: 'Resources',
            messages: ['individualAssessment'],
        },
    ],
    messages: {
        welcomeMessage: {
            title: 'Welcome Message',
            message:
                'Welcome to Building Healing Systems! This platform includes assessment tools and resources to support teams engaging in continuous quality improvement processes to foster trauma-informed and healing-centered organizational practices, such as including people with lived experience in decision making and training staff to connect families to culturally responsive services and supports. Building healing systems is an ongoing process that requires a commitment to growth and change. That\'s why throughout this platform, you will see references to trees, growth, and gardening. The focus of your efforts will look different given the "season" (e.g., wants, needs, capacity, resources, barriers, etc.).',
        },
        organizationAssessment: {
            title: 'Organization Assessment',
            message: `<p>Start by completing the Building Healing Systems Trauma-Informed Organizational Assessment, which asks about how frequently your organization uses trauma-informed practices.</p> <p>The information collected will be used by an implementation team identified by your organization to identify strengths and areas of growth for continuous quality.</p>`,
        },
        individualAssessment: {
            title: 'Resources',
            message: `
			<ul>
			<li>Use custom reports of results to understand your organization's areas of strength and areas for growth.</li>
			<li>Use the resource library to inform continuous quality improvement processes to increase trauma-informed practices.</li>
			<li>Visit the Data to Action Toolkit for additional information and resources.</li>
		  </ul>
			`,
        },
    },
};
