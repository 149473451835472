import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';

import { client as apollo } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import IconWarning from 'cccisd-icons/notification';
import IconReset from 'cccisd-icons/reset';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';

import IndividualReport from 'js/vendor/reports/reportTemplates/Individual';
import ConfirmationModal from 'js/components/ConfirmationModal';
import parentDeploymentIdQuery from './parentDeploymentId.graphql';
import completionStatusQuery from './completionStatus.graphql';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;
const appDefs = window.cccisd.appDefs;

const IndividualAssessment = () => {
    const pawnId = Fortress.user.acting.id;

    const [isLoading, setIsLoading] = useState(true);
    const [assessmentComplete, setAssessmentComplete] = useState(false);
    const [parentDeploymentId, setParentDeploymentId] = useState(-1);
    const [deploymentId, setDeploymentId] = useState(-1);

    useEffect(() => {
        (async () => {
            const parentDeploymentResp = await apollo.query({
                query: parentDeploymentIdQuery,
                fetchPolicy: 'network-only',
            });
            const parentId = _get(parentDeploymentResp, 'data.flows.deployment.deploymentId');
            if (!parentId || parentId < 1) {
                // probably need to add deployment handle
                setIsLoading(false);
            } else {
                setParentDeploymentId(parentId); // triggers next useEffect hook
            }
        })();
    }, []);

    // Once we fetch parent deployment ID, get the child deployment that should be shown
    useEffect(() => {
        if (parentDeploymentId && parentDeploymentId > 0) {
            (async () => {
                const childrenProgressResp = await apollo.query({
                    query: completionStatusQuery,
                    variables: { parentDeploymentId },
                    fetchPolicy: 'network-only',
                });

                const childDeploymentId = _get(childrenProgressResp, 'data.flows.assignmentProgress.deploymentId');
                if (!childDeploymentId) {
                    // first pass through, they have no assignmentProgress yet, so force it
                    forceNextChildDeployment();
                    return;
                }

                const isComplete =
                    _get(childrenProgressResp, 'data.flows.assignmentProgress.completed', false) || false;

                setDeploymentId(childDeploymentId);
                setAssessmentComplete(isComplete);
                setIsLoading(false);
            })();
        }
    }, [parentDeploymentId]);

    async function forceNextChildDeployment() {
        setAssessmentComplete(false);
        setIsLoading(true);
        let repeatedDeploymentResp = await axios.get(
            Boilerplate.route('api.assignmentDeployment.repeated', {
                deploymentId: parentDeploymentId,
                pawnId,
                hash: Fortress.user.acting.respondent_hash,
            }),
            // passing `true` or nothing because backend interprets `false` as truthy string
            { params: { forceRepeat: true } }
        );

        const responseDeploymentId = _get(repeatedDeploymentResp, 'data.data.deploymentId');
        setDeploymentId(responseDeploymentId || -1);
        setIsLoading(false);
    }

    const getReportSettings = () => {
        const reportList = appDefs.reports.list;

        const anyOrgReport = reportList.find(r => r.reportTemplateHandle === 'individual');
        if (anyOrgReport) {
            return anyOrgReport.reportTemplateSettings;
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    const organizationInfo = window.cccisd.fortress.user.acting.group.type_data;
    const reportTrigger = (
        <div>
            <button
                type="button"
                className="btn btn-primary"
                style={{ marginRight: '1em' }}
                disabled={!assessmentComplete}
            >
                View Report
            </button>
        </div>
    );

    return (
        <div className={style.wrapper}>
            <div className={style.menu}>
                <h2>Individual Assessment</h2>
                <div className={style.separate_2_buttons}>
                    <Modal
                        trigger={
                            assessmentComplete ? (
                                reportTrigger
                            ) : (
                                <Tooltip title="Complete the assessment to see a report.">{reportTrigger}</Tooltip>
                            )
                        }
                        size="large"
                        title="Individual Assessment Report"
                    >
                        <div>
                            <IndividualReport.player
                                settings={getReportSettings()}
                                deploymentId={deploymentId}
                                isIndividual
                            />
                        </div>
                    </Modal>
                    <Modal
                        trigger={
                            <Tooltip title="Restart Survey">
                                <button type="button" className="btn btn-primary">
                                    <IconReset />
                                </button>
                            </Tooltip>
                        }
                        size="small"
                        title="Are you sure?"
                    >
                        <ConfirmationModal
                            message="Do you want to restart the survey?"
                            onSubmit={forceNextChildDeployment}
                        />
                    </Modal>
                </div>
            </div>
            <div>
                {!deploymentId || deploymentId === -1 ? (
                    <div className="jumbotron" style={{ width: '900px', margin: '0 auto' }}>
                        <h1>
                            <IconWarning />
                        </h1>
                        <p>There is no open Individual Assessment right now.</p>
                    </div>
                ) : (
                    <DeploymentPlayer
                        deploymentId={deploymentId}
                        pawnId={Fortress.user.acting.id}
                        pawnHash={Fortress.user.acting.random_hash}
                        flowProps={{
                            variables: {
                                isBHO: organizationInfo.isBHO ? 1 : 0,
                            },
                        }}
                        onComplete={() => {
                            setAssessmentComplete(true);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default IndividualAssessment;
