import React from 'react';
import Tabs from 'cccisd-tabs';
import OverviewFields from './OverviewFields';
// import OrgReport from './OrgReport';

const tabList = [
    { name: 'overviewFields', title: 'Overview', content: <OverviewFields /> },
    // { name: 'orgReportFields', title: 'Org Report', content: <OrgReport /> },
];

const SiteContent = props => {
    return (
        <div className="container">
            <h1>Edit Site Content</h1>
            <Tabs tabList={tabList} />
        </div>
    );
};

export default SiteContent;
