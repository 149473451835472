import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

export default class ApproveModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func,
        message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        onSubmit: PropTypes.func,
        buttonText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    };

    static defaultProps = {
        buttonText: 'Confirm',
    };

    handleYesButton = () => {
        this.props.onSubmit();
        this.props.closeModal();
    };

    handleCancelButton = () => {
        this.props.closeModal();
    };

    render() {
        return (
            <div className={style.modalBody}>
                <p>{this.props.message}</p>
                <div className={style.modalButtons}>
                    <button
                        type="button"
                        className="btn btn-default"
                        onClick={this.handleCancelButton}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.handleYesButton}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        );
    }
}
