import React from 'react';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';
import orgIcon from '../../../images/Overview_Planting.png';
import wateringIcon from '../../../images/Overview_Watering Can.png';

const Boilerplate = window.cccisd.boilerplate;

const Overview = props => {
    return (
        <div className={style.container}>
            <div className={style.category}>
                <a href={Boilerplate.url('/orgAssessment')} style={{ textDecoration: 'none' }}>
                    <div className={style.subheading}>
                        <div className={style.triangle}>
                            <span className={style.icon}>
                                <img src={orgIcon} alt="" />
                            </span>
                        </div>
                        <div className={style.shText}>Look at current practices</div>
                    </div>
                    <div className={style.text}>
                        <Html content={props.orgAssessmentText} />
                    </div>
                </a>
            </div>
            <div className={style.category}>
                <a href={Boilerplate.url('/individualAssessment')} style={{ textDecoration: 'none' }}>
                    <div className={style.subheading}>
                        <div className={style.triangle}>
                            <span className={style.icon}>
                                <img src={wateringIcon} alt="" />
                            </span>
                        </div>
                        <div className={style.shText}>Nurture areas that need care</div>
                    </div>
                    <div className={style.text}>
                        <Html content={props.individualAssesmentText} />
                    </div>
                </a>
            </div>
        </div>
    );
};

export default Overview;
