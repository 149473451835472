import React from 'react';
import PropTypes from 'prop-types';
import { updateParams, getSavedParams } from 'js/reducers/providerwellbeing.js';
import { connect } from 'react-redux';
import Form from './form.js';
import Loader from 'cccisd-loader';
import overviewFields from 'js/reducers/siteFields/overviewFields.js';

class Params extends React.Component {
    static propTypes = {
        params: PropTypes.object,
        updateParams: PropTypes.func,
        getSavedParams: PropTypes.func,
    };

    state = {
        loading: true,
        formWasUpdated: false,
    };

    componentDidMount = async () => {
        await this.props.getSavedParams(overviewFields, 'overview');
        this.setState({ loading: false });
    };

    onSubmit = async values => {
        await this.props.updateParams(values, 'overview');
        this.setState({ formWasUpdated: false });
    };

    updateForm = () => {
        this.setState({ formWasUpdated: true });
    };

    render() {
        if (this.state.loading) {
            return <Loader loading />;
        }
        return (
            <Form
                initialValues={this.props.params}
                onSubmit={this.onSubmit}
                fields={Object.keys(this.props.params)}
                updateForm={this.updateForm}
                formWasUpdated={this.state.formWasUpdated}
            />
        );
    }
}

const mapStateToProps = state => ({
    params: state.app.providerwellbeing.overviewFields,
});

export default connect(mapStateToProps, { updateParams, getSavedParams })(Params);
