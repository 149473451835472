import React from 'react';
import { connect } from 'react-redux';
import Loader from 'cccisd-loader';
import List from './overview.js';
import { getSavedParams } from 'js/reducers/providerwellbeing.js';
import overviewFields from 'js/reducers/siteFields/overviewFields.js';
import style from './style.css';
import { Html } from 'cccisd-wysiwyg';

const mapStateToProps = state => {
    return {
        overviewFields: state.app.providerwellbeing.overviewFields,
    };
};

export default connect(mapStateToProps, { getSavedParams })(
    class Overview extends React.Component {
        state = {
            loading: true,
        };

        componentDidMount = async () => {
            if (!this.props.overviewFields) {
                await this.props.getSavedParams(overviewFields, 'overview');
            }

            this.setState({ loading: false });
        };

        render() {
            if (this.state.loading) {
                return <Loader />;
            }
            return (
                <div className={style.content}>
                    <div className={style.topBox}>
                        <Html content={this.props.overviewFields.welcomeMessage} />
                    </div>
                    <List
                        orgAssessmentText={this.props.overviewFields.organizationAssessment}
                        orgAssessmentAdditionalContent={this.props.overviewFields.orgAssessmentAdditionalContent}
                        individualAssesmentText={this.props.overviewFields.individualAssessment}
                        resourceCenterText={this.props.overviewFields.resourceCenter}
                        manageText={this.props.overviewFields.manage}
                    />
                </div>
            );
        }
    }
);
