import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FieldBlock } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import Editor from 'cccisd-wysiwyg';
import Accordion from '../Accordion';
import overviewFields from 'js/reducers/siteFields/overviewFields.js';
import style from '../style.css';

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        values: PropTypes.object,
        updateForm: PropTypes.func,
        formWasUpdated: PropTypes.bool,
    };

    componentDidUpdate = (prevProps, prevState) => {
        for (const [key] of Object.entries(prevProps.values)) {
            if (prevProps.values[key] !== this.props.values[key]) {
                this.props.updateForm();
            }
        }
    };

    render() {
        const { fields } = this.props;

        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className={style.wrapper}>
                    <div className={style.content}>
                        {overviewFields.domains.map(domain => (
                            <Accordion title={domain.title} key={domain.title}>
                                <div key={domain.title}>
                                    <h3>{domain.title}</h3>
                                    {domain.messages.map(handle => (
                                        <FieldBlock
                                            key={handle}
                                            field={fields[handle]}
                                            label={overviewFields.messages[handle].title}
                                        >
                                            <Editor
                                                content={fields[handle].value}
                                                onChange={fields[handle].onChange}
                                            />
                                        </FieldBlock>
                                    ))}
                                </div>
                            </Accordion>
                        ))}
                    </div>
                    <div className={style.button}>
                        <ClickButton
                            title="Save"
                            isLoading={this.props.submitting}
                            onClick={this.props.handleSubmit}
                            className={
                                this.props.formWasUpdated ? 'btn btn-warning' : 'btn btn-primary'
                            }
                        />
                    </div>
                </div>
            </form>
        );
    }
}

export default reduxForm({ form: 'MessagesForm' })(Form);
