import React, { useState } from 'react';
import IconArrowRight from 'cccisd-icons/arrow-right20';
import IconArrowDown from 'cccisd-icons/arrow-down20';
import style from './style.css';

export default props => {
    const [isCollapsed, setCollapsed] = useState(true);

    const toggleCollapsed = () => {
        setCollapsed(!isCollapsed);
    };

    return (
        <div className={style.wrapper}>
            <div className={style.header} onClick={toggleCollapsed}>
                {isCollapsed ? <IconArrowRight spaceRight /> : <IconArrowDown spaceRight />}
                <span className={style.headerText}>{props.title}</span>
            </div>
            {!isCollapsed && <div className={style.content}>{props.children}</div>}
        </div>
    );
};
